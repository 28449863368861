<template>
  <div>
    <vs-row>
      <vs-col v-for="module in notificationSettingsUrlsList" type="flex" vs-justify="center" vs-align="center" vs-w="4">
        <vs-card>
          <div slot="header">
            <h3>{{ module.name }}</h3>
          </div>
          <div>
            <vs-list>
              <vs-list-item title="Acciones">
                <div class="flex">
                  <vx-tooltip :text="'Destinatarios'">
                    <vs-button
                        radius size="large" color="dark" type="flat" icon-pack="feather" :icon="'icon-at-sign'"
                        @click="listNotificationSettingsEntryTypes(module.entry_type)"
                    />
                  </vx-tooltip>
                  <vx-tooltip :text="'Reportes programados'">
                    <vs-button
                        radius size="large" color="dark" type="flat" icon-pack="feather" :icon="'icon-clock'"
                        @click="listScheduledReports(module.entry_type)"
                    />
                  </vx-tooltip>
                </div>
              </vs-list-item>
            </vs-list>
          </div>
        </vs-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        notificationSettingsUrlsList: {
          "assets": {
            "name": "Activos Eventuales",
            "entry_type": "assets",
          },
          "static-pages": {
            "name": "Páginas estaticas",
            "entry_type": "static-pages",
          },
          "jobs": {
            "name": "Plazas Disponibles",
            "entry_type": "jobs",
          },
          "promotions": {
            "name": "Promociones",
            "entry_type": "promotions",
          },
          "products": {
            "name": "Productos",
            "entry_type": "products",
          },
          "projects": {
            "name": "Proyectos Inmobiliarios",
            "entry_type": "projects",
          },
        },
        pageTitle: 'Notificaciones',
      };
    },

    methods: {
      listNotificationSettingsEntryTypes(entry_type) {
        this.$router.push({name: "notification-settings", params: {entry_type: entry_type}});
      },

      listScheduledReports(entry_type) {
        let routeName = "list-scheduled-report-notifications-settings"
        this.$router.push({name: routeName, params: {entry_type: entry_type}});
      },

      createNotificationSettingsEntryTypes(entry_type) {
        let routeName = `notification-settings-create-${entry_type}`
        this.$router.push({name: routeName, params: {entry_type: entry_type}});
      },
    },
  };
</script>


<style scoped>
  .vs-table--tbody-table .tr-values.selected {
    cursor: default;
  }
</style>